import {graphql} from 'gatsby';
import React from 'react';
import './terms.css';

import Layout from '../components/layout';
import SEO from '../components/seo';

const TermsPage = ({data: {markdownRemark}}) => (
  <Layout>
    <SEO title="Terms of Service - Fleet Serverless function fastest" />
    <div className="relative mt-12 md:mt-24 px-6 lg:px-12">
      <div className="container">
        <div className="flex flex-col w-full lg:w-3/6 lg:pr-12">
          <p className="text-gray mb-2">LEGAL</p>
          <h1 className="text-3xl sm:text-4xl md:text-5xl leading-tight font-semibold">
            Terms of Service
          </h1>
          <p className="text-gray mt-8 leading-relaxed">
            Last update: {markdownRemark.frontmatter.lastUpdate}
          </p>
        </div>
        <div className="relative mt-16 text-lg">
          <div
            className="terms-content"
            dangerouslySetInnerHTML={{
              __html: markdownRemark.html,
            }}
          />
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  {
    markdownRemark(fileAbsolutePath: {regex: "/legal/terms/"}) {
      html
      frontmatter {
        lastUpdate
      }
    }
  }
`;

export default TermsPage;
